import React from 'react';
import styles from "./AnimeCard.module.css";
import {Link} from "react-router-dom";
import {litelibria_poster_url} from "../../../../api/constants";

const AnimeCard = ({ anime }) => {
    const path = `/title/${anime.code}`;
    return (
        <Link
            to={path}
            className={styles.title}
            key={anime.code}
        >
            <img
                className={styles.poster}
                src={`${litelibria_poster_url}${anime.id}.webp`}
                alt="poster"
                loading="lazy"
            />
        </Link>
    );
};

export default AnimeCard;
