
export function ProfileName(){
    let nicknamee = localStorage.getItem('userNickname');
    let photoUrl;
    if(nicknamee !== null && nicknamee.length > 0){
        photoUrl = nicknamee;
    } else {
        photoUrl = localStorage.getItem('userLogin');
    }

    return photoUrl;
}


export function ProfilePic(){
    let photoUrll = localStorage.getItem('photoUrl');
    let photoUrl;
    if(photoUrll !== null && photoUrll.length > 0){
        photoUrl = photoUrll;
    } else {
        photoUrl = 'https://i.pinimg.com/564x/a0/f8/ec/a0f8ec21b43de1f5457da922d81e6baf.jpg';
    }

    return photoUrl;
}

// https://i.pinimg.com/originals/d8/14/f9/d814f949b165a98d15f5e0dfa73f0055.gif
// https://images-ext-1.discordapp.net/external/tdElJj8jEOipe5Alsuwgh2XAMoxP4_khCNz81rZ5JFc/https/hdqwalls.com/wallpapers/anime-sunset-scene-b8.jpg

export function ProfileBanner(){
    let bannerUrll = localStorage.getItem('bannerUrl');
    let bannerUrl;
    if(bannerUrll !== null && bannerUrll.length > 0){
        bannerUrl = bannerUrll;
    } else {
        bannerUrl = 'https://images-ext-2.discordapp.net/external/wgJJnWsTi9aVW5zHKCwBTykv6mCssUk0CH9CVPeTUpk/https/i.pinimg.com/originals/4b/af/9a/4baf9a11778a31c5a2d1b05615d4d4e9.gif';
    }

    return bannerUrl;
}