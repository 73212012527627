import React from 'react';

function KodikPlayer({ player }) {
    return (
        <iframe title="player"
                src={player.alternative_player.split('?')[0].replace('aniqit.com', 'kodik.info')}
                width="100%" height={window.innerWidth < 768 ? '220px' : '550px'}
                frameborder="0"
                allow="fullscreen *"></iframe>
    );
}

export default KodikPlayer;
