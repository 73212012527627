import styles from './Loading.module.css';

const Loading = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-white dark:bg-[#141414]">
            <div className={styles["lds-heart"]}><div></div></div>
        </div>
    );
};

export default Loading;
