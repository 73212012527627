import React from 'react';
import styling from './EpisodeSelector.module.css';
import {MediaMenu, MediaMenuButton, MediaMenuItems, MediaRadio, MediaRadioGroup} from "@vidstack/react";
import {PlaylistIcon} from "@vidstack/react/icons";

const EpisodeSelector = ({ episodes, currentEpisode, onEpisodeChange }) => {
    const handleEpisodeSelect = (e) => {
        onEpisodeChange(parseInt(e.target.value));
    };

    return (
        <MediaMenu position="bottom left" className={styling["playlist-menu"]}>
            <MediaMenuButton aria-label="Playlist">
                <PlaylistIcon />
            </MediaMenuButton>
            <MediaMenuItems>
                <MediaRadioGroup
                    className={styling["radio-group"]}
                    value={currentEpisode}
                    onClick={handleEpisodeSelect}
                >
                    {episodes.map((episode, index) => (
                        <MediaRadio key={index} value={episode.episode}>
                            {`Серия ${episode.episode}`}
                        </MediaRadio>
                    ))}
                </MediaRadioGroup>
            </MediaMenuItems>
        </MediaMenu>
    );
};

export default EpisodeSelector;
