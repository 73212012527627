import React from 'react';
import {useParams} from 'react-router-dom';
import Loading from '../Loading/Loading';
import Design from './Design/Design';
import {useGetAnimeQuery, useGetAnimeSearchQuery} from '../../api/anilibriaSlice';
import Error from '../Error';

function Mind() {

	const params = useParams();

	window.scrollTo({
		top: 0,
		behavior: 'auto',
	});

	const title = useGetAnimeQuery({
		code: params.code,
		filter: 'id,names,franchises,genres,description,season,status.string,type.full_string,player'
	});

	const { data: titleData, isError: titleIsError, isLoading: titleIsLoading } = title;

	const similar = useGetAnimeSearchQuery(
		{
			query: `genres=${titleData?.genres?.slice(0, 3).join(',')}`,
			filter: 'id,code',
			params: '&limit=10',
		},
		{ skip: !titleData }
	);

	const { data: similarData, isError: similarIsError, isLoading: similarIsLoading } = similar;


	if(titleIsLoading || similarIsLoading){
		return (<Loading/> );
	}
	else if(titleIsError || similarIsError){
		return (<Error/>);
	}
	else{
		return (<Design title={titleData} similar={similarData}/>);
	}
}

export default Mind;
