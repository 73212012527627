import React, {useState} from 'react';
import Login from './Login/Login';
import AnimeCard from "../TitleList/AnimeCard/AnimeCard";
import ScrollToTopButton from "../ScrollToTopButton";
import ProfileModal from "./ProfileModal";
import {ProfileName, ProfilePic, ProfileBanner} from "./ProfileInfo";
import {useGetAnimeFavoritesQuery} from "../../api/anilibriaSlice";
import Loading from "../Loading/Loading";
import Error from "../Error";
import { motion } from "framer-motion";

const Account = ({changeTheme}) => {
    const [userKey, setUserKey] = useState(localStorage.getItem('userKey'));
    const [showPopup, setShowPopup] = useState(false);

    const {data: favorites, isError, isLoading, isSuccess} = useGetAnimeFavoritesQuery({
        filter: "id,code,names.ru,genres,type.episodes,status.code,player.episodes",
        session: userKey,
        limit: `&limit=-1`,
    });

    const handleLogout = () => {
        localStorage.removeItem('userKey');
        localStorage.removeItem('userLogin');
        localStorage.removeItem('userNickname');
        localStorage.removeItem('photoUrl');
        localStorage.removeItem('bannerUrl');
        setUserKey(null);
    };

    const handleLoggedIn = () => {
        setUserKey(localStorage.getItem('userKey'));
    };

    const handlePopupClose = () => {
        setShowPopup(!showPopup);
    };

    let photoUrl = ProfilePic();

    let bannerUrl = ProfileBanner();


    if(userKey === null){
        return (<Login onLoggedIn={handleLoggedIn} />);
    }
    else if(isLoading){
        return (<Loading/>);
    }
    else if(isError){
        return (<Error/>);
    }
    else if(isSuccess) {
        return (
            <>
                <div className="flex flex-col mb-[100px] mx-[10px]">
                    <div>
                        <div className="relative w-full h-[300px]">
                            <motion.img
                                src={bannerUrl}
                                alt="Profile Banner"
                                className="w-full opacity-0 h-full object-cover rounded-tl-lg rounded-tr-lg"
                                animate={{opacity: 1}}
                                transition={{delay: 0.5}}
                            />
                            <div className="flex  flex-col justify-center items-center absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-[125%]">
                                <motion.div className="opacity-0 w-[150px] h-[150px] rounded-full overflow-hidden"
                                            animate={{opacity: 1}}
                                            transition={{delay: 0.6}}>
                                    <img
                                        src={photoUrl}
                                        alt=""
                                        className="w-full h-full object-cover rounded-full mb-[10px]"
                                    />
                                </motion.div>
                                <motion.h2 className="text-3xl opacity-0 font-bold text-black dark:text-white my-2"
                                           animate={{opacity: 1}}
                                           transition={{delay: 0.7}}>{ProfileName()}</motion.h2>
                                <motion.p className="text-lg opacity-0 text-[#7A7A7A]"
                                          animate={{opacity: 1}}
                                          transition={{delay: 0.8}}>Котик Даниме</motion.p>
                            </div>
                        </div>
                    </div>
                    <motion.div className="flex opacity-0 justify-between sm:justify-end mt-5"
                                animate={{opacity: 1}}
                                transition={{delay: 2}}>
                        <button
                            className="text-[#7A7A7A] text-sm font-semibold px-4 py-2 border-2 border-[#7A7A7A] rounded-lg mr-2 hover:border-[#FF007A] transition-colors"
                            onClick={handlePopupClose}
                        >
                            Настройки
                        </button>
                        <button
                            className="text-[#980037] text-sm font-semibold px-4 py-2 border-2 border-[#980037] rounded-lg hover:border-[#c90048] transition-colors"
                            onClick={handleLogout}
                        >
                            Выйти
                        </button>
                    </motion.div>
                </div>
                <div className="flex flex-col text-center items-center">
                    <motion.h2 className="text-2xl opacity-0 sm:text-5xl text-black dark:text-white my-6"
                               animate={{opacity: 1}}
                               transition={{delay: 1.1}}>ВАШИ ИЗБРАННЫЕ ТАЙТЛЫ</motion.h2>
                    <motion.div className="flex opacity-0 flex-wrap justify-center"
                                animate={{opacity: 1}}
                                transition={{delay: 1.5}}>
                        {favorites.list.slice().reverse().map((title) => (
                            <div key={title.code} className="mb-4">
                                <AnimeCard title={title} />
                            </div>
                        ))}
                    </motion.div>
                </div>


                <ProfileModal showPopup={showPopup} changeTheme={changeTheme} handlePopupClose={handlePopupClose} photoUrll={photoUrl} bannerUrll={bannerUrl} />
                <ScrollToTopButton/>
            </>
        );
    }
};

export default Account;
