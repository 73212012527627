import React, { useState } from 'react';
import {Link, NavLink} from "react-router-dom";
import {FaTiktok} from "react-icons/fa";
import {BsDiscord, BsInstagram, BsTelegram} from "react-icons/bs";

function BurgerMenu({link, RefreshTitle, styledLink}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className="burgerMenu min-w-[30px] h-[22px] mx-[10px] flex lg:hidden">
            <div className="menuIcon flex flex-col justify-between w-[30px] h-[22px] cursor-pointer" onClick={toggleMenu}>
                <div className="iconBar min-w-[30px] h-0.5 bg-black dark:bg-white"/>
                <div className="iconBar min-w-[30px] h-0.5 bg-black dark:bg-white"/>
                <div className="iconBar min-w-[30px] h-0.5 bg-black dark:bg-white"/>
            </div>
            {isOpen && (
                <div className="menuContent fixed top-0 left-0 w-full h-full bg-[#FFFFFFF2] dark:bg-[#101010F2] flex flex-col justify-center items-center z-50 text-white text-center text-[30px] backdrop-filter backdrop-blur-sm">
                    <div className={`absolute top-[30px] right-[30px] w-[40px] h-[40px] cursor-pointer`} onClick={toggleMenu}>
                        <span className="absolute top-1/2 left-0 w-full h-0.5 bg-black dark:bg-white transform -translate-y-1/2 rotate-45"></span>
                        <span className="absolute top-1/2 left-0 w-full h-0.5 bg-black dark:bg-white transform -translate-y-1/2 -rotate-45"></span>
                    </div>
                    <div className="flex flex-col links mb-8" onClick={toggleMenu}>
                        <NavLink to="/list"
                              className="link text-[#939393] hover:text-[#FF005C] mb-[25px]"
                              style={styledLink}
                        >Список</NavLink>

                        <NavLink to="/schedule"
                              className="link text-[#939393] hover:text-[#FF005C] mb-[25px]"
                              style={styledLink}
                        >Расписание</NavLink>

                        <Link to={link} onClick={RefreshTitle} className="link text-[#939393] hover:text-[#FF005C] mb-[25px]">Случайное</Link>

                        <NavLink to="/support"
                              className="link text-[#939393] hover:text-[#FF005C] mb-[25px]"
                              style={styledLink}
                        >Поддержать</NavLink>
                        
                        <NavLink to="/account"
                              className="link text-[#939393] hover:text-[#FF005C]"
                              style={styledLink}
                        >Личный кабинет</NavLink>
                    </div>
                    <div className="social flex flex-row space-x-7 space-between rounded-2xl bg-white shadow-custom dark:bg-[#262626] py-5 px-8 mb-8">
                        <a href="https://www.tiktok.com/@danimeua" target="_blank" rel="noreferrer">
                            <FaTiktok className="ico text-[#111111] dark:text-white w-[30px] h-[30px]"/>
                        </a>
                        <a href="https://t.me/danimeua" target="_blank" rel="noreferrer">
                            <BsTelegram className="ico text-[#111111] dark:text-white w-[30px] h-[30px]"/>
                        </a>
                        <a href="https://www.instagram.com/rediblecompany/" target="_blank" rel="noreferrer">
                            <BsInstagram className="ico text-[#111111] dark:text-white w-[30px] h-[30px]"/>
                        </a>
                        <a href="https://discord.gg/gHkSuAVhdk" target="_blank" rel="noreferrer">
                            <BsDiscord className="ico text-[#111111] dark:text-white w-[30px] h-[30px]"/>
                        </a>
                    </div>
                    <div className="contact absolute bottom-6">
                        <p className="p1 text-xs text-[#0000007F] dark:text-[#FFFFFF7F] mb-1">СВЯЗАТЬСЯ С НАМИ</p>
                        <p className="p2 text-sm text-[#111111] dark:text-white">rediblecompany@gmail.com</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BurgerMenu;
