import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {anilibria_url} from "./constants";

const baseQuery = fetchBaseQuery({
    baseUrl: anilibria_url
});

export const anilibriaSlice = createApi({
    reducerPath: "title",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAnimeList: builder.query({
            query: (options) => ({
                url: `/title/updates?filter=${options.filter}${options.limit}`,
            }),
        }),
        getAnimeSchedule: builder.query({
            query: (filter) => ({
                url: `/title/schedule?filter=${filter}`,
            }),
        }),
        getAnime: builder.query({
            query: (options) => ({
                url: `/title?code=${options.code}&filter=${options.filter}&remove=torrents`,
            }),
        }),
        getAnimeSearch: builder.query({
            query: (options) => ({
                url: `/title/search?${options.query}&filter=${options.filter}${options.params}`,
            }),
        }),
        getAnimeRandom: builder.query({
            query: (filter) => ({
                url: `/title/random?filter=${filter}`,
            }),
        }),
        getAnimeFavorites: builder.query({
            query: (options) => ({
                url: `/user/favorites?filter=${options.filter}&session=${options.session}${options.limit}`,
            }),
        }),
        getGenres: builder.query({
            query: () => ({
                url: `/genres`,
            }),
        }),
        getYears: builder.query({
            query: () => ({
                url: `/years`,
            }),
        }),
    }),
});

export const {
    useGetAnimeListQuery,
    useGetAnimeScheduleQuery,
    useGetAnimeQuery,
    useGetAnimeSearchQuery,
    useGetAnimeRandomQuery,
    useGetAnimeFavoritesQuery,
    useGetGenresQuery,
    useGetYearsQuery,
} = anilibriaSlice;