import axios from "axios";
import {anilibria_url} from "../../../api/constants";

export default async function SaveLogin(userKey){
    try {
        const { data } = await axios.get(`${anilibria_url}/user?session=${userKey}&filter=login`);
        localStorage.setItem('userLogin', data.login)
    } catch (error) {
        console.error(error);
    }
}