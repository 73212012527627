import React, {useCallback, useEffect, useState} from 'react';
import {useGetAnimeListQuery} from '../../api/anilibriaSlice';
import Loading from '../Loading/Loading';
import AnimeCard from './AnimeCard/AnimeCard';
import useDebounce from '../../hooks/useDebounce';
import ScrollToTopButton from '../ScrollToTopButton';
import Error from '../Error';
import {motion} from "framer-motion";

function TitleList() {
	const [limit, setLimit] = useState(0);
	const [titleList, setTitleList] = useState([]);

	const { data, isError, isLoading} = useGetAnimeListQuery({
		filter: 'id,code,names.ru,genres,type.episodes,status.code,player.episodes',
		limit: `&limit=36${ limit > 0 ? `&after=${limit}` : '' }`
	});

	useEffect(() => {
		if (data) {
			setTitleList((prevTitleList) => [...prevTitleList, ...data.list]);
		}
	}, [data]);

	const debouncedHandleScroll = useCallback(() => {
		if(isLoading){
			return;
		}

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollHeight = document.documentElement.scrollHeight;
		const clientHeight = document.documentElement.clientHeight;

		if (scrollTop + clientHeight >= scrollHeight - 2) {
			setLimit((limit) => limit + 36);
		}
	}, [isLoading]);

	const debouncedScroll = useDebounce(debouncedHandleScroll, 300);

	useEffect(() => {
        
		const handleScroll = () => debouncedScroll();

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [debouncedScroll]);

	if(isLoading){
		return (<Loading/>);
	}
	else if(isError){
		return (<Error/>);
	}
	else {
		return (
			<motion.div className="opacity-0"
						animate={{opacity: 1}}
						transition={{delay: 0.5}}>
				<div className="flex flex-wrap justify-center h-full">
					{titleList.map((title) => (
						<div key={title.code}>
							<AnimeCard title={title} />
						</div>
					))}
				</div>
				<div className="flex justify-center items-center h-[100px]">
					<div className="w-[55px] h-[55px] border-4 border-opacity-20 border-[#FF005C33] border-t-[#FF005CFF] rounded-full animate-spin" />
				</div>
				<ScrollToTopButton/>
			</motion.div>
		);
	}


}

export default TitleList;