import React, {useState} from 'react';
import Select from 'react-select';
import styles from "./ZalupaPlayer.module.css";

function ZalupaPlayer({ player, playersList, playerID, playerText, onChangePlayer }) {
    const [episode, setEpisode] = useState(player.episodes.first);
    const [url, setURL] = useState(player.rutube[episode].rutube_id);

    if(url !== player.rutube[episode].rutube_id){
        setEpisode(player.episodes.first);
        setURL(player.rutube[episode].rutube_id);
    }

    const episodes = Object.keys(player.rutube).map((key) => {
        return {
            value: key,
            label: `Серия ${key}`,
        };
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#262626',
            color: '#FFFFFF',
            border: state.isFocused ? '2px solid #262626' : '2px solid #262626',
            boxShadow: state.isFocused ? 'none' : 'none',
            borderRadius: "10px",
            '&:hover': {
                border: state.isFocused ? '2px solid #262626' : '2px solid #262626'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#212121' : '#262626',
            color: '#FFFFFF',
            borderRadius: "10px",
            '&:hover': {
                backgroundColor: '#212121'
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#262626',
            marginTop: '5px',
            borderRadius: "10px",
        })
    };

    const handleEpisodeChange = (selectedOption) => {
        const episode = selectedOption.value;
        const url = player.rutube[episode].rutube_id;
        setEpisode(episode);
        setURL(url);
    };



    return (
        <div>
            <div className={styles.selectors}>
                <div className={styles["selectors-left"]}>
                    <Select
                        className={styles.selectorLeft}
                        value={{ value: episode, label: `Серия ${episode}` }}
                        options={episodes}
                        onChange={handleEpisodeChange}
                        styles={customStyles}
                    />
                </div>
                <div className={styles["selectors-right"]}>
                    <Select
                        className={styles.selectorRight}
                        value={{ value: playerID, label: playerText }}
                        options={playersList}
                        onChange={onChangePlayer}
                        styles={customStyles}
                        isSearchable={false}
                    />
                </div>
            </div>
            <iframe
                title="zalupa"
                src={`https://rutube.ru/embed/${url}?skinColor=da124c`}
                width="100%"
                height={window.innerWidth < 768 ? '220px' : '550px'}
                frameborder="0"
                allow="fullscreen *"
            />
        </div>
    );
}

export default ZalupaPlayer;
