import logoMini from "../../images/logoMini.svg";
import {Link, NavLink} from "react-router-dom";
import Loading from "../Loading/Loading";
import {ProfileName, ProfilePic} from "../Account/ProfileInfo";
import {useGetAnimeRandomQuery} from "../../api/anilibriaSlice";
import Error from "../Error";
import BurgerMenu from "./components/BurgerMenu";
import SearchInput from "./components/SearchInput";
import { motion } from "framer-motion";


function Header() {

    let userKey = localStorage.getItem('userKey');

    const {data, isError, isLoading, refetch} = useGetAnimeRandomQuery("code");

    const RefreshTitle = async () => {
        await refetch();
    };

    const styledLink = ({ isActive}) => {
        return {
            color: isActive && "#FF005C",
        };
    }


    if(isLoading){
        return (<Loading/>);
    }
    else if(isError){
        return (<Error/>);
    }
    else {

        let linkRandomAnime = `/title/${data.code}`;

        return(
            <motion.header className="header opacity-0 mx-[10px] my-[10px] flex items-center justify-between bg-white shadow-custom dark:bg-[#1D1D1D] rounded-lg p-[15px]"
                    animate={{opacity: 1}}
                    transition={{delay: 0.1}}>
                    <div className="logo-links flex flex-row">
                        <Link to="/" className="logo flex w-[50px] h-[50px]">
                            <motion.img name="logoMini"
                                 src={logoMini}
                                 className="logo opacity-0 flex w-[50px] h-[50px]"
                                 alt="logoMini"
                                 animate={{opacity: 1}}
                                 transition={{delay: 0.2}}
                            />
                        </Link>
                        <nav className="links hidden lg:flex items-center ml-2">
                            <motion.div animate={{opacity: 1}}
                                        transition={{delay: 0.3}}
                                        className="opacity-0">
                                <NavLink to="/list"
                                         className="link flex text-[#939393] mr-5 hover:text-[#FF005C]"
                                         style={styledLink}
                                >
                                    Список
                                </NavLink>
                            </motion.div>

                            <motion.div animate={{opacity: 1}}
                                        transition={{delay: 0.4}}
                                        className="opacity-0">
                                <NavLink to="/schedule"
                                         className="link flex text-[#939393] mr-5 hover:text-[#FF005C]"
                                         style={styledLink}>
                                    Расписание
                                </NavLink>
                            </motion.div>

                            <motion.div animate={{opacity: 1}}
                                        transition={{delay: 0.5}}
                                        className="opacity-0">
                                <Link to={linkRandomAnime} onClick={RefreshTitle} className="link flex text-[#939393] mr-5 hover:text-[#FF005C]">Случайное</Link>
                            </motion.div>

                            <motion.div animate={{opacity: 1}}
                                        transition={{delay: 0.6}}
                                        className="opacity-0">
                                <NavLink to="/support"
                                         className="link flex text-[#939393] hover:text-[#FF005C]"
                                         style={styledLink}>
                                    Поддержать
                                </NavLink>
                            </motion.div>
                        </nav>
                    </div>
                <SearchInput/>
                <motion.div animate={{opacity: 1}}
                            transition={{delay: 0.1}}
                            className="opacity-0">
                    {userKey === null ? (
                        <div className="buttons hidden lg:flex flex-row text-center ">
                            <a href="https://www.anilibria.tv/pages/cp.php" target="_blank" rel="noreferrer">
                                <button className="text-white text-base h-10 w-40 rounded-lg border-0 px-4 ml-4 bg-gradient-to-r from-[#FF005C] to-[#FF007A]" name="register-btn">Регистрация</button>
                            </a>
                            <Link to="/account">
                                <button className="text-[#7A7A7A] text-base  h-10 w-24 rounded-lg border-2 border-[#7A7A7A] px-4 ml-4 transition duration-300 hover:border-[#FF005C]" name="login-btn">Войти</button>
                            </Link>
                        </div>
                    ) : (
                        <Link to="/account" className="account hidden lg:flex items-center min-w-[120px] mx-[10px]" >
                            <p className="felx mx-2 text-black dark:text-white text-base">{ProfileName()}</p>
                            <img src={ProfilePic()}
                                 className="flex w-[50px] h-[50px] rounded-full"
                                 name="avatar"
                                 alt="avatar"
                            />
                        </Link>
                    )}
                </motion.div>
                <BurgerMenu link={linkRandomAnime} RefreshTitle={RefreshTitle} styledLink={styledLink}/>
            </motion.header>
        );
    }

}

export default Header;