import SwiperCore from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import AnimeCard from './TitleList/AnimeCard/AnimeCard';
import Loading from "./Loading/Loading";
import ScrollToTopButton from "./ScrollToTopButton";
import {useGetAnimeScheduleQuery} from "../api/anilibriaSlice";
import Error from "./Error";
import { motion } from "framer-motion";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

const AnimeSchedule = () => {

    const {data, isError, isLoading} = useGetAnimeScheduleQuery("id,code,names.ru,genres,type.episodes,status.code,player.episodes");


    const swiperResponsive = {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        361:{
            slidesPerView: 2,
            spaceBetween: 10,
        },
        588:{
            slidesPerView: 3,
            spaceBetween: 5,
        },
        700:{
            slidesPerView: 4,
            spaceBetween: 5,
        },
        810: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1179:{
            slidesPerView: 5,
            spaceBetween: 10,
        },
        1280: {
            slidesPerView: 5,
            spaceBetween: 30,
        },
        1920: {
            slidesPerView: 6,
            spaceBetween: 40,
        },
        2500:{
            slidesPerView: 8,
            spaceBetween: 40,
        },
    };


    if(isLoading){
        return (<Loading/>);
    }
    else if(isError){
        return (<Error/>);
    }
    else {

        const currentDay = new Date().getDay();

        const sortedSchedule = [...data].sort((a, b) => {
            const dayA = (a.day - currentDay + 8) % 7;
            const dayB = (b.day - currentDay + 8) % 7;
            return dayA - dayB;
        });

        return (
            <>
                <motion.div className="opacity-0"
                            animate={{opacity: 1}}
                            transition={{delay: 0.5}}>
                    {sortedSchedule.map((daySchedule, index) => (
                        <div key={index} className="mt-7">
                            <h2 className="text-black dark:text-white text-center text-2xl sm:text-3xl md:text-4xl">{daysOfWeek[daySchedule.day]}</h2>
                            <Swiper
                                navigation
                                pagination={{ clickable: true }}
                                breakpoints={swiperResponsive}
                            >
                                {daySchedule.list.map(anime => (
                                    <SwiperSlide key={anime.code}>
                                        <AnimeCard title={anime} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    ))}
                </motion.div>

                <ScrollToTopButton/>
            </>
        );
    }
};

export default AnimeSchedule;
