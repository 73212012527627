import { Link } from 'react-router-dom';
import animeGirlImage from '../images/anime-girl404.png';
import {motion} from "framer-motion";

const PageNotFound = () => {
    return (
        <motion.div className="flex opacity-0 flex-col items-center justify-center h-screen text-center"
                    animate={{opacity: 1}}
                    transition={{delay: 0.5}}>
            <img src={animeGirlImage} alt="Anime Girl" className="w-64 mb-5" />
            <h1 className="text-4xl font-bold text-black dark:text-white mb-3">Oops! Error</h1>
            <p className="text-lg text-[#888888] mb-5">
                Something get wrong.
            </p>
            <Link to="/" className="text-lg text-blue-500 hover:underline">
                Go to homepage
            </Link>
        </motion.div>
    );
};

export default PageNotFound;
