const Modal = ({showPopup, handlePopupClose, children}) => {
  return(
      <>
          {showPopup && (
              <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#FFFFFF7F] dark:bg-[#0000007F] bg-opacity-40 backdrop-blur-sm flex justify-center items-center z-50" onClick={handlePopupClose}>
                  <div className="bg-white dark:bg-[#1d1d1d] text-black dark:text-white text-center p-5 rounded-lg max-w-2xl mx-4 relative flex flex-col justify-center items-center" onClick={(e) => e.stopPropagation()}>
                      {children}
                      <button className="bg-[#ff005c] text-white text-dase border-none px-4 py-2 mt-3 mb-0 rounded-md w-24" onClick={handlePopupClose}>
                          Закрыть
                      </button>
                  </div>
              </div>
          )}
      </>
  );
};

export default Modal;