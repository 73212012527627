import './FavButton.scss';
import axios from "axios";
import { useEffect, useState } from "react";
import {anilibria_url} from "../../../api/constants";
import Modal from "../../Modal";

function FavButton({ titleID, className }) {
    const [isFavorite, setIsFavorite] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = `${anilibria_url}/user/favorites?session=${localStorage.getItem("userKey")}`;
                const { data } = await axios.get(`${apiUrl}&filter=id&limit=-1`);

                if (Array.isArray(data.list) && data.list.length > 0) {
                    setIsFavorite(data.list.some((title) => title.id === titleID));
                }
            } catch (error) {
                console.error(error);
            }
        };

        if(localStorage.getItem("userKey") !== null){
            fetchData();
        }
    }, [titleID]);

    const handleFavoriteToggle = async () => {
        const session = localStorage.getItem("userKey");
        if (session !== null) {
            try {
                const apiUrl = `${anilibria_url}/user/favorites?session=${session}&title_id=${titleID}`;
                await axios[isFavorite ? "delete" : "put"](apiUrl);
                setIsFavorite(!isFavorite);
            } catch (error) {
                console.error(error);
            }
        } else {
            setShowPopup(true);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };


    return (
        <div id="main-content">
            <div className={className}>
                <input
                    type="checkbox"
                    id="checkbox"
                    checked={isFavorite}
                    onChange={handleFavoriteToggle}
                />
                <label htmlFor="checkbox">
                    <svg
                        className="svg-heart"
                        id="heart-svg"
                        viewBox="467 392 58 57"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" id="heart" stroke="#ce0045" strokeWidth="2.5"/>
                            <circle id="main-circ" opacity="0" cx="29.5" cy="29.5" r="1.5"/>

                            <g id="grp7" opacity="0" transform="translate(7 6)">
                                <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2"/>
                                <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2"/>
                            </g>

                            <g id="grp6" opacity="0" transform="translate(0 28)">
                                <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2"/>
                                <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2"/>
                            </g>

                            <g id="grp3" opacity="0" transform="translate(52 28)">
                                <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2"/>
                                <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2"/>
                            </g>

                            <g id="grp2" opacity="0" transform="translate(44 6)">
                                <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2"/>
                                <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2"/>
                            </g>

                            <g id="grp5" opacity="0" transform="translate(14 50)">
                                <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2"/>
                                <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2"/>
                            </g>

                            <g id="grp4" opacity="0" transform="translate(35 50)">
                                <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2"/>
                                <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2"/>
                            </g>

                            <g id="grp1" opacity="0" transform="translate(24)">
                                <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2"/>
                                <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2"/>
                            </g>
                        </g>
                    </svg>

                </label>
            </div>
            <Modal handlePopupClose={handlePopupClose} showPopup={showPopup} children={
                <>
                    <h3 className="popup-h3">Неа</h3>
                    <p>Пожалуйста, авторизуйтесь для добавления в избранное.</p>
                </>
            }/>
        </div>
    );
}

export default FavButton;
