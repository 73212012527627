import {HashRouter, Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { Helmet } from 'react-helmet';
import './App.css';
import ico from './images/miniLogo.ico';
import StartPage from './components/HomePage';
import TitleList from './components/TitleList/TitleList';
import TitleInfo from './components/TitleInfo/Mind';
import Account from './components/Account/Account';
import Support from './components/Support';
import AnimeSchedule from './components/AnimeSchedule';
import PageNotFound from './components/PageNotFound';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Search from './components/Search';

function App() {
	let them = localStorage.getItem('theme');
	const [theme, setTheme] = useState( them ? them : 'dark');

	useEffect(() => {
		document.body.style.backgroundColor = theme === 'dark' ? '#141414' : '#FFFFFF';
		localStorage.setItem('theme', theme);
	}, [theme]);

	const handleThemeChange = (text) =>{
		setTheme(text);
	}

	return (
		<div className={theme}>
			<Helmet>
				<title>Danime</title>
				<link rel="icon" href={ico} sizes="16x16" />
			</Helmet>
			<HashRouter>
				<Header />
				<Routes>
					<Route path="/" element={<StartPage />} />
					<Route path="/list" element={<TitleList />} />
					<Route path="/schedule" element={<AnimeSchedule />} />
					<Route path="/title/:code" element={<TitleInfo />} />
					<Route path="/search/:text" element={<Search />} />
					<Route path="/account" element={<Account changeTheme={handleThemeChange} />} />
					<Route path="/support" element={<Support />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
				<Footer />
			</HashRouter>
		</div>
	);
}

export default App;
