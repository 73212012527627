import {useGetAnimeSearchQuery} from "../../../api/anilibriaSlice";
import React, {useEffect, useState} from "react";
import useDebounce from "../../../hooks/useDebounce";
import {Link} from "react-router-dom";
import animeGirl from '../../../images/anime-girlSearch.png';
import { motion } from "framer-motion";

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import FilterMenu from "./FilterMenu";
import {litelibria_poster_url} from "../../../api/constants";

function SearchInput() {

    const [inputText, setInputText] = useState('');
    const [query, setQuery] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showPopupFilter, setShowPopupFilter] = useState(false);

    const handlePopupFilterClose = () => {
        setShowPopupFilter(false);
    };

    const handleFilterClick = () => {
        setShowPopupFilter(!showPopupFilter);
    };

    const {data: searchDate, isSuccess} = useGetAnimeSearchQuery({
        query: query,
        filter: "id,code,names.ru,genres",
        params: `&limit=4`,
    });

    const debouncedSearch = useDebounce((txt) => setQuery(`search=${txt}`), 510);

    const handleKeyPress = (e) => {
        let text = e.target.value;
        setInputText(text);
        setShowLoading(true);
        if(text.length > 0){
            debouncedSearch(text);
        }else if(text.length === 0){
            setShowLoading(false);
            setQuery('');
        }
    };

    const handleSelectTitle = () => {
        setInputText('');
        setQuery('');
    }

    useEffect(() => {
        if(isSuccess || searchDate?.length > 0){
            setShowLoading(false);
        }
    }, [isSuccess, searchDate]);

    let loadMore = `/search/${query}`;
    
    return(
        <motion.div animate={{opacity: 1}}
                    transition={{delay: 1}}
                    className="relative max-w-[700px] w-full opacity-0">
            <div className="searchBox relative flex flex-row max-w-[700px] min-w-0 ml-4 mr-4">
                <div className="search-loading content-center flex flex-col w-full">
                    <input
                        className="search border-0 bg-[#EFEFEF] dark:bg-[#141414] placeholder-[#4a4a4a] text-[#7A7A7A] text-base text-center flex rounded-lg p-1 min-w-0 h-10 mr-1 outline-none"
                        type="text"
                        name="search-imput"
                        placeholder="Найти аниме"
                        value={inputText}
                        onChange={handleKeyPress}
                    />
                    {showLoading && (
                        <Stack className="loading absolute top-[90%] px-6 flex max-w-[620px]" sx={{ width: '90%', color: '#FF005C' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    )}
                </div>
                <div className="filter border-0 bg-[#EFEFEF] dark:bg-[#141414] hidden sm:flex justify-center items-center rounded-lg w-[50px] cursor-pointer ml-1" onClick={handleFilterClick}>
                    <svg className="fill-black dark:fill-white w-8 h-8" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.1247 30.6448C23.1863 31.1072 23.0322 31.6005 22.6777 31.9242C22.5351 32.0671 22.3657 32.1805 22.1792 32.2578C21.9927 32.3352 21.7928 32.375 21.5909 32.375C21.3891 32.375 21.1892 32.3352 21.0027 32.2578C20.8162 32.1805 20.6468 32.0671 20.5042 31.9242L14.323 25.743C14.1549 25.5786 14.027 25.3776 13.9495 25.1557C13.8719 24.9337 13.8468 24.6968 13.876 24.4636V16.5713L6.4924 7.12216C6.24209 6.80081 6.12914 6.39345 6.17824 5.98908C6.22734 5.58471 6.4345 5.21622 6.75445 4.96412C7.04733 4.74832 7.37103 4.625 7.71015 4.625H29.2905C29.6296 4.625 29.9533 4.74832 30.2462 4.96412C30.5662 5.21622 30.7733 5.58471 30.8224 5.98908C30.8715 6.39345 30.7586 6.80081 30.5082 7.12216L23.1247 16.5713V30.6448ZM10.8547 7.70791L16.9589 15.5077V24.0165L20.0418 27.0994V15.4923L26.1459 7.70791H10.8547Z"/>
                    </svg>
                </div>
                {isSuccess && (
                    <div className={`absolute top-[170%] left-0 w-full shadow-md bg-white dark:bg-[#1d1d1d] pt-2 rounded-lg z-30 transition-opacity duration-300 ease-in-out ${ inputText === '' ? 'opacity-0 invisible' : 'opacity-1 visible'}`}>
                        {searchDate.list.length > 0 ? (
                            <>
                                {searchDate.list.slice(0, 3).map((anime, index) => (
                                    <Link onClick={handleSelectTitle} to={`/title/${anime.code}`} className="flex items-center mb-4 no-underline text-[#111111] dark:text-white px-4 mx-1 rounded-lg hover:bg-[#EFEFEF] dark:hover:bg-[#4E4E4E]" key={index}>
                                        <img
                                            className="w-full h-20 my-1 rounded-lg mr-5 object-cover max-w-[80px]"
                                            src={`${litelibria_poster_url}${anime.id}.webp`}
                                            alt="poster"
                                            loading="lazy"
                                        />
                                        <div className="flex flex-col overflow-hidden">
                                            <h3 className="text-lg mb-1 overflow-hidden overflow-ellipsis whitespace-nowrap">{anime.names.ru}</h3>
                                            <p className="text-sm text-[#888] overflow-hidden overflow-ellipsis whitespace-nowrap">{anime.genres.join(', ')}</p>
                                        </div>
                                    </Link>
                                ))}
                                {searchDate.list.length > 3 && (
                                    <Link to={loadMore} onClick={handleSelectTitle}>
                                        <button className="text-white  bg-[#FF005C] border-0 rounded-b-lg text-md cursor-pointer py-0.5 w-full">Ещё</button>
                                    </Link>
                                )}
                            </>
                        ) : (
                            inputText !== '' && (
                                <div className="flex flex-col items-center text-center">
                                    <img
                                        className="max-w-[150px]"
                                        src={animeGirl}
                                        alt="notFound"
                                        loading="lazy"
                                    />
                                    <h2 className="text-2xl mt-0 mb-3 text-[#111111] dark:text-white">Ничего не найдено</h2>
                                </div>
                            )
                        )}
                    </div>

                )}
            </div>
            <FilterMenu showPopup={showPopupFilter} handlePopupClose={handlePopupFilterClose} />
        </motion.div>
    );
}

export default SearchInput;