import React, { useState, useEffect } from 'react';
import {HiArrowUp} from "react-icons/hi";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible(scrollTop > 300);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button
            className={`flex justify-center items-center fixed bottom-10 right-10 w-[50px] h-[50px] bg-[#FF005C] text-white text-center border-none text-2xl rounded-full cursor-pointer  transition-opacity z-10 ${isVisible ? 'opacity-1' : 'opacity-0'}`}
            onClick={scrollToTop}
        >
            <HiArrowUp className="w-full h-full p-3" />
        </button>
    );
};

export default ScrollToTopButton;
