import {Link, useParams} from "react-router-dom";
import {useGetAnimeSearchQuery} from "../api/anilibriaSlice";
import Loading from "./Loading/Loading";
import Error from "./Error";
import AnimeCard from "./TitleList/AnimeCard/AnimeCard";
import ScrollToTopButton from "./ScrollToTopButton";
import React from "react";
import animeGirlImage from "../images/anime-girlSearch.png";
import {motion} from "framer-motion";

function Search() {

    const params = useParams();

    const {data, isLoading, isError} = useGetAnimeSearchQuery({
        query: params.text,
        filter: "id,code,names.ru,genres,type.episodes,status.code,player.episodes",
        params: `&limit=72`,
    });

    if(isLoading){
        return (<Loading/>);
    }
    else if(isError){
        return (<Error/>);
    }
    else {
        return (
            <motion.div className="opacity-0"
                        animate={{opacity: 1}}
                        transition={{delay: 0.5}}>
                <div className="flex flex-wrap justify-center h-full">
                    { data?.list.length > 0 ? (
                        <>
                            {data?.list.map((title) => (
                                <div key={title.code}>
                                    <AnimeCard title={title} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-screen text-center">
                            <img src={animeGirlImage} alt="Anime Girl" className="w-64 mb-5" />
                            <h1 className="text-4xl font-bold text-black dark:text-white mb-3">Ничего не найдено</h1>
                            <Link to="/" className="text-lg text-blue-500 hover:underline">
                                Вернуться назад
                            </Link>
                        </div>
                        )}

                </div>
                <ScrollToTopButton/>
            </motion.div>
        );
    }
}

export default Search;
