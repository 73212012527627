import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';
import './VidstackPlayer.css';
import {
    MediaCommunitySkin,
    MediaOutlet,
    MediaPlayer,

} from '@vidstack/react';
import EpisodeSelector from "./EpisodeSelector/EpisodeSelector";
import useDebounce from "../../../../hooks/useDebounce";
import { useState } from "react";
// import SkipButton from "./SkipButton/SkipButton";

const VidstackPlayer = ({ player, titleId }) => {

    const storedEpisode = localStorage.getItem(`episode of ${titleId}`);
    const [episode, setEpisode] = useState(storedEpisode ? parseInt(storedEpisode) : player.episodes.first);

    const episodes = Object.values(player.list);
    let preloadedTime = localStorage.getItem(`videoTime of ${titleId} ${episode} episode`);
    const [videoTime, setVideoTime] = useState(preloadedTime);

    const handleEpisodeChange = (episode) => {
        localStorage.setItem(`episode of ${titleId}`, episode);
        setEpisode(episode);
    };

    const handleVolumeChange = (newVolume) => {
        localStorage.setItem('videoVolume', newVolume.detail.volume);
    };

    const videoVolume = localStorage.getItem('videoVolume');
    const initialVolume = videoVolume !== null
        ? parseFloat(videoVolume)
        : 0.5;

    const handlePause = (newTime) => {
        localStorage.setItem(`videoTime of ${titleId} ${episode} episode`, newTime.trigger.explicitOriginalTarget.currentTime);
    };

    const handleSrcChange = () => {
        setVideoTime(localStorage.getItem(`videoTime of ${titleId} ${episode} episode`));
    };


    // const [currentTime, setCurrentTime] = useState(preloadedTime);
    // const handleTimeUpdate = (e) => {
    //     setCurrentTime(e.detail.currentTime);
    //
    // };


    // const handleSkip = (time) => {
    //     localStorage.setItem(`videoTime of ${titleId} ${episode} episode`, time);
    //     setVideoTime(time);
    // };

    const createHLSPlaylist = (player, episodeNumber) => {
        const episode = player.list[episodeNumber];
        let playlist = "#EXTM3U\n";

        if (episode.hls.fhd) {
            playlist += `#EXT-X-STREAM-INF:BANDWIDTH=8000000,RESOLUTION=1920x1080\nhttps://${player.host}${episode.hls.fhd}\n`;
        }
        if (episode.hls.hd) {
            playlist += `#EXT-X-STREAM-INF:BANDWIDTH=6000000,RESOLUTION=1280x720\nhttps://${player.host}${episode.hls.hd}\n`;
        }
        if (episode.hls.sd) {
            playlist += `#EXT-X-STREAM-INF:BANDWIDTH=2800000,RESOLUTION=854x480\nhttps://${player.host}${episode.hls.sd}\n`;
        }

        return playlist;
    };

    const playlist = URL.createObjectURL(new Blob([createHLSPlaylist(player, episode)], { type: 'application/x-mpegurl' }));


    return (
        <MediaPlayer
            title={player.list[episode].name}
            src={{
                src: playlist,
                type: 'application/x-mpegurl',
            }}
            aspect-ratio={16 / 9}
            width="100%"
            height="100%"
            crossorigin
            preferNativeHLS={true}

            onPause={handlePause}
            currentTime={videoTime}

            volume={initialVolume}
            onVolumeChange={useDebounce(handleVolumeChange, 1500)}

            onSourceChange={handleSrcChange}

            // onTimeUpdate={handleTimeUpdate}

            keyShortcuts={{
                togglePaused: 'k Space',
                toggleMuted: 'm',
                toggleFullscreen: 'f',
                togglePictureInPicture: 'i',
                toggleCaptions: 'c',
                seekBackward: 'ArrowLeft',
                seekForward: 'ArrowRight',
                volumeUp: 'ArrowUp',
                volumeDown: 'ArrowDown',
            }}
        >

            <MediaOutlet>
            </MediaOutlet>
            <MediaCommunitySkin>
                {/*<SkipButton skips={player.list[episode].skips} currentTime={currentTime} onSkip={handleSkip}/>*/}
                <EpisodeSelector
                    episodes={episodes}
                    currentEpisode={episode}
                    onEpisodeChange={handleEpisodeChange}
                />
            </MediaCommunitySkin>
        </MediaPlayer>
    );
};

export default VidstackPlayer;
