import React, { useState } from 'react';
import styles from './Design.module.css';
import FavButton from '../AddTitleToFavorites/FavButton';
import AnimeSlider from "./AnimeSlider/AnimeSlider";
import PlayerMind from "../Players/PlayerMind";
import TitleRating from "../TitleRating";
import Modal from "../../Modal";
import { motion } from "framer-motion";
import {litelibria_poster_url} from "../../../api/constants";

const PlayerMindMemoized = React.memo(PlayerMind);

function Design({ title, similar }) {
    const { id, names, season, franchises, genres, description, status, type, player } = title;
    const [showPopupPoster, setShowPopupPoster] = useState(false);
    const [showPopupDescription, setShowPopupDescription] = useState(false);

    const handlePopupPosterClose = () => {
        setShowPopupPoster(false);
    };

    const handlePosterClick = () => {
        setShowPopupPoster(!showPopupPoster);
    };

    const handlePopupDescriptionClose = () => {
        setShowPopupDescription(false);
    };

    const handleDescriptionClick = () => {
        setShowPopupDescription(!showPopupDescription);
    };

    let date = '';
    if (season.string) {
        date += season.string + ' ';
    }
    if (season.year) {
        date += season.year;
    }

    return (
        <motion.div className={styles.main}
                    animate={{opacity: 1}}
                    transition={{delay: 0.5}}>
            <div className={styles['outer-title-container']}>
                <div className={styles['title-container']}>
                    <div className={styles['title-rating']}>
                        <div className={styles['poster-container']}>
                            <img
                                className={styles.poster}
                                src={`${litelibria_poster_url}${id}.webp`}
                                alt={names.en}
                                onClick={handlePosterClick}
                                loading="lazy"
                            />
                            <Modal handlePopupClose={handlePopupPosterClose} showPopup={showPopupPoster}>
                                <img
                                    className={styles.modalPoster}
                                    src={`${litelibria_poster_url}${id}.webp`}
                                    alt={names.en}
                                    loading="lazy"
                                />
                            </Modal>
                            <FavButton className={styles['fav-button']} titleID={id} />
                        </div>
                    </div>
                    <div className={styles['title-text']}>
                        <div className={styles['title-name-description']} onClick={handleDescriptionClick}>
                            <h2 className={`${styles.name} text-black dark:text-white`}>{names.ru}</h2>
                            <p className={`${styles.description} text-[#0000007F] dark:text-[#FFFFFF7F]`}>{description}</p>
                        </div>
                        <Modal handlePopupClose={handlePopupDescriptionClose} showPopup={showPopupDescription}>
                            <>
                                <h2 className={`${styles.modalName} text-black dark:text-white`}>{names.ru}</h2>
                                <p className={`${styles.modalDescription} text-[#0000007F] dark:text-[#FFFFFF7F]`}>{description}</p>
                            </>
                        </Modal>
                        <div className={styles['anime-specifications']}>
                            <p className={`${styles.ppp} text-[#0000007F] dark:text-[#FFFFFF7F]`}>
                                <strong className={`${styles['name-spec']} text-black dark:text-white`}>Статус: </strong>
                                {status.string}
                            </p>
                            <p className={`${styles.ppp} text-[#0000007F] dark:text-[#FFFFFF7F]`}>
                                <strong className={`${styles['name-spec']} text-black dark:text-white`}>Жанры: </strong>
                                {genres.join(', ')}
                            </p>
                            <p className={`${styles.ppp} text-[#0000007F] dark:text-[#FFFFFF7F]`}>
                                <strong className={`${styles['name-spec']} text-black dark:text-white`}>Год релиза: </strong>
                                {date}
                            </p>
                            <p className={`${styles.ppp} text-[#0000007F] dark:text-[#FFFFFF7F]`}>
                                <strong className={`${styles['name-spec']} text-black dark:text-white`}>Тип: </strong>
                                {type.full_string}
                            </p>
                        </div>
                        <div className={styles.rating}>
                            <TitleRating names={names} />
                        </div>
                    </div>
                </div>
                <div className={styles.player}>
                    <PlayerMindMemoized id={id} player={player} />
                </div>
                <p className={`${styles['mobile-description']} text-[#0000007F] dark:text-[#FFFFFF7F]`}>{description}</p>
            </div>
            {franchises.length > 0 && (
                <section className={`${styles['outer-franchises-container']} bg-white dark:bg-[#1D1D1DCC] shadow-custom`}>
                    <h2 className="text-black dark:text-white">Связанные релизы</h2>
                    <AnimeSlider animeArr={franchises[0].releases} />
                </section>
            )}
            {similar !== null && (
                <section className={`${styles['outer-franchises-container']} bg-white dark:bg-[#1D1D1DCC] shadow-custom`}>
                    <h2 className="text-black dark:text-white">Вам также может понравиться</h2>
                    <AnimeSlider animeArr={similar.list} />
                </section>
            )}
        </motion.div>
    );
}

export default Design;

