import React, {useState} from 'react';
import Select from "react-select";
import {useGetGenresQuery, useGetYearsQuery} from "../../../api/anilibriaSlice";
import { useNavigate  } from 'react-router-dom';
import Slider from '@mui/material/Slider';


function FilterMenu({showPopup, handlePopupClose}) {

    let navigate = useNavigate();

    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedYears, setSelectedYears] = React.useState([2004, 2016]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState([]);



    const {data: genres} = useGetGenresQuery();
    const {data: years} = useGetYearsQuery();

    const handleSearch = () => {
        let query = "";

        if (selectedGenres.length > 0) {
            query += `genres=${selectedGenres.map(genre => genre.value).join(',')}&`;
        }

        if (selectedYears.length > 0) {
            let startYear = selectedYears[0];
            let endYear = selectedYears[1];
            let yearsRange = [];

            for (let year = startYear; year <= endYear; year++) {
                yearsRange.push(year);
            }

            query += `year=${yearsRange.join(',')}&`;
        }

        if (selectedType.length > 0) {
            query += `type=${selectedType.map(type => type.value).join(',')}&`;
        }

        if (selectedSeason.length > 0) {
            query += `season_code=${selectedSeason.map(season => season.value).join(',')}&`;
        }

        if (query.endsWith('&')) {
            query = query.slice(0, -1);
        }

        navigate(`/search/${query}`);
        handlePopupClose();
    };


    const handleChange = (event, newValue) => {
        setSelectedYears(newValue);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#262626',
            color: '#FFFFFF',
            border: state.isFocused ? '2px solid #1d1d1d' : '2px solid #1d1d1d',
            boxShadow: state.isFocused ? 'none' : 'none',
            '&:hover': {
                border: state.isFocused ? '2px solid #1d1d1d' : '2px solid #1d1d1d'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#1d1d1d' : '#262626',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#1d1d1d'
            }
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#1d1d1d',
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#262626',
            marginTop: '2px',
            borderRadius: '4px'
        })
    };

    return (
        <>
            {showPopup && (
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-40 backdrop-blur-sm flex justify-center items-center z-50" onClick={handlePopupClose}>
                    <div className="w-[750px] bg-white dark:bg-[#1d1d1d] text-black dark:text-white text-center p-5 rounded-xl max-w-2xl mx-4 relative flex flex-col justify-center items-center" onClick={(e) => e.stopPropagation()}>
                        <h3 className="flex text-2xl mb-8">Фильтр</h3>
                        <Slider
                            style={{ color: '#FF005C'}}
                            className="mb-5"
                            value={selectedYears}
                            onChange={handleChange}
                            valueLabelDisplay="on"
                            max={years[years.length-1]}
                            min={years[0]}
                        />
                        <div className="flex flex-col w-full mb-3">
                            <Select
                                placeholder="Жанры"
                                className="felx w-full mb-1"
                                isMulti
                                name="title-genre"
                                classNamePrefix="select"
                                styles={customStyles}
                                isSearchable={false}
                                closeMenuOnSelect={false}
                                options={genres.map(genre => ({ value: genre, label: genre }))}
                                onChange={selectedGenres => {
                                    setSelectedGenres(selectedGenres);
                                }}
                            />
                            <Select
                                placeholder="Тип"
                                className="felx w-full mb-1"
                                isMulti
                                name="title-type"
                                classNamePrefix="select"
                                styles={customStyles}
                                isSearchable={false}
                                closeMenuOnSelect={false}
                                options={[
                                    { value: 0, label: "Фильм" },
                                    { value: 1, label: "TV" },
                                    { value: 2, label: "OVA" },
                                    { value: 3, label: "ONA" },
                                    { value: 4, label: "Спешил" },
                                    { value: 5, label: "WEB" },
                                ]}
                                onChange={selectedType => {
                                    setSelectedType(selectedType);
                                }}
                            />
                            <Select
                                placeholder="Сезон"
                                className="felx w-full"
                                isMulti
                                name="title-type"
                                classNamePrefix="select"
                                styles={customStyles}
                                isSearchable={false}
                                closeMenuOnSelect={false}
                                options={[
                                    { value: 1, label: "Зима" },
                                    { value: 2, label: "Весна" },
                                    { value: 3, label: "Лето" },
                                    { value: 4, label: "Осень" },
                                ]}
                                onChange={selectedType => {
                                    setSelectedSeason(selectedType);
                                }}
                            />
                        </div>
                        <button className="bg-[#ff005c] text-white text-dase border-none px-4 py-2 mt-3 mb-0 rounded-lg w-24" onClick={handleSearch}>Искать</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default FilterMenu;