import {useState} from 'react';
import {BsFillMoonStarsFill, BsFillSunFill} from "react-icons/bs";

const ProfileModal = ({ showPopup, handlePopupClose, photoUrll, bannerUrll, changeTheme }) => {

    let photo = localStorage.getItem('photoUrl');
    let banner = localStorage.getItem('bannerUrl');
    let nick = localStorage.getItem('userNickname');

    const [photoUrl, setPhotoUrl] = useState(photo !== null ? photo : '');
    const [bannerUrl, setBannerUrl] = useState(banner !== null ? banner : '');
    const [nickname, setNickname] = useState(nick !== null ? nick : '');
    const [theme, setTheme] = useState(localStorage.getItem('theme') === 'dark');

    const handleNickmameChange = (event) => {
        setNickname(event.target.value);
    };

    const handlePhotoUrlChange = (event) => {
        setPhotoUrl(event.target.value);
    };

    const handleBannerUrlChange = (event) => {
        setBannerUrl(event.target.value);
    };

    const handleThemeChange = () => {
        changeTheme(!theme ? 'dark' : 'light');
        setTheme(!theme);
    };

    const handleSave = () => {
        if(nick !== nickname){
            localStorage.setItem('userNickname', nickname);
        }
        if(photo !== photoUrl){
            localStorage.setItem('photoUrl', photoUrl);
        }
        if(banner !== bannerUrl){
            localStorage.setItem('bannerUrl', bannerUrl);
        }

        handlePopupClose();
        window.location.reload(false);
    };

    return (
        <>
            {showPopup && (
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-40 backdrop-blur-sm bg-[#FFFFFF66] dark:bg-[#00000066] flex justify-center items-center z-50" onClick={handlePopupClose}>
                    <div className="max-w-[700px] w-full bg-white dark:bg-[#1d1d1d] shadow-custom text-white text-center rounded-lg mx-4 flex relative flex-col justify-center items-center" onClick={(e) => e.stopPropagation()}>
                        <div className="relative w-full mb-[100px]">
                            <img src={bannerUrll}
                                 className="w-full h-[150px] object-cover rounded-t-lg"
                                 alt="Profile Banner"
                                 loading="lazy"
                            />
                            <div className="absolute m-2 p-2 bg-[#EFEFEF] rounded-lg dark:bg-[#262626] cursor-pointer" onClick={handleThemeChange}>
                                {theme ?
                                    <BsFillMoonStarsFill className="w-7 h-7 text-[#c90048]"/>
                                    :
                                    <BsFillSunFill className="w-7 h-7 text-[#ff005c]"/>
                                }
                            </div>
                        </div>
                        <div className="absolute bottom-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-[25%]">
                            <img src={photoUrll}
                                 className="w-[100px] h-[100px] object-cover mb-2 rounded-full overflow-hidden"
                                 alt="Profile Picture"
                                 loading="lazy"
                            />
                            <h2 className="text-3xl font-bold text-black dark:text-white mb-1">Кастомизация</h2>
                        </div>
                        <div className="w-full max-w-[280px] flex flex-col items-center mt-8">
                            <input
                                className="w-full max-w-[280px] h-[45px] p-[10px] mb-[10px] border-none rounded-lg placeholder-[#535353] bg-[#EFEFEF] dark:bg-[#262626] text-[#7A7A7A] text-center outline-none"
                                type="text"
                                name="nickname-input"
                                placeholder="Никнейм"
                                value={nickname}
                                onChange={handleNickmameChange}
                                maxLength={14}
                            />
                            <input
                                className="w-full max-w-[280px] h-[45px] p-[10px] mb-[10px] border-none rounded-lg placeholder-[#535353] bg-[#EFEFEF] dark:bg-[#262626] text-[#7A7A7A] text-center outline-none"
                                type="text"
                                name="photoProfile-input"
                                placeholder="Ссылка на фото профиля"
                                value={photoUrl}
                                onChange={handlePhotoUrlChange}
                            />
                            <input
                                className="w-full max-w-[280px] h-[45px] p-[10px] mb-[10px] border-none rounded-lg placeholder-[#535353] bg-[#EFEFEF] dark:bg-[#262626] text-[#7A7A7A] text-center outline-none"
                                type="text"
                                name="bannerProfile-input"
                                placeholder="Ссылка на баннер профиля"
                                value={bannerUrl}
                                onChange={handleBannerUrlChange}
                            />
                            <button className="w-full max-w-[260px] bg-[#ff005c] text-white font-semibold py-2 px-4 rounded-lg"
                                    onClick={handleSave}>
                                Применить
                            </button>
                        </div>
                        <a href="https://www.anilibria.tv/pages/cp.php"
                           target="_blank" rel="noreferrer"
                           className="max-w-[260px] mt-1 text-[#7A7A7A] hover:text-[#ff005c] text-xs max-w-260 mb-10"
                        >
                            Тыкните сюда что бы изменить логин или пароль!
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfileModal;
