import videoBackgroundSupport from "../images/videoBackgroundSupport.mp4";
import {motion} from "framer-motion";

function Support() {

    return (
        <motion.div className="relative opacity-0 overflow-hidden"
                    animate={{opacity: 1}}
                    transition={{delay: 0.5}}>
            {localStorage.getItem('theme') === 'light' && (
                <>
                    <div className="absolute top-0 left-0 w-full h-full z-[-1] backdrop-blur-[3px]" style={{ background: 'linear-gradient(to top, rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.19))' }}></div>
                    <video className="absolute top-0 left-0 w-full h-full object-cover z-[-2]" autoPlay loop muted>
                        <source src={videoBackgroundSupport} type="video/mp4" />
                    </video>
                </>
            )}
            <div className="flex flex-col items-center justify-center text-center h-[80vh] p-3">
                <h2 className="text-black dark:text-white text-5xl font-bold mb-10">ПОДДЕРЖКА СОЗДАТЕЛЕЙ</h2>
                <p className="max-w-lg text-[#4f4f4f] dark:text-[#959595] text-xl mb-10">Благодарим вас за то что поддерживаете нас!</p>
                <p className="max-w-xl text-[#4f4f4f] dark:text-[#959595] text-lg mb-0">Автори сайту НЕ підтримують війну в Україні, а посилання на Anilibria зроблено на знак подяки за безкоштовний API.</p>
                <div className="flex flex-col sm:flex-row sm:space-x-7 justify-center items-center w-full mt-2">
                    <a href="https://socprofile.com/redible" target="_blank" title="SocProfile" rel="noreferrer">
                        <button className="w-full max-w-[375px] h-14 bg-[#FF005C] text-white border-none px-8 py-2 mb-4 text-xl rounded-xl hover:bg-[#da124c] transition duration-300">Поддержать авторов сайта</button>
                    </a>
                    <a href="https://www.anilibria.tv/pages/donate.php" target="_blank" title="Anilibria" rel="noreferrer">
                        <button className="w-full max-w-[375px] h-14 bg-[#FF005C] text-white border-none px-8 py-2 mb-4 text-xl rounded-xl hover:bg-[#da124c] transition duration-300">Поддержать Anilibria</button>
                    </a>
                </div>
            </div>
        </motion.div>
    );
}

export default Support;
