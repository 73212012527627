import {combineReducers, configureStore} from "@reduxjs/toolkit";

import { anilibriaSlice } from "./api/anilibriaSlice";
import { shikimoriSlice } from "./api/shikimoriSlice";

const rootReducer = combineReducers({
    [anilibriaSlice.reducerPath]: anilibriaSlice.reducer,
    [shikimoriSlice.reducerPath]: shikimoriSlice.reducer,
})
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(anilibriaSlice.middleware)
            .concat(shikimoriSlice.middleware),
});

export default store;
