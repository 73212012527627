import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { shikimori_url } from "./constants";

const baseQuery = fetchBaseQuery({
    baseUrl: shikimori_url
});

export const shikimoriSlice = createApi({
    reducerPath: "score",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAnimeScore: builder.query({
            query: (name) => ({
                url: `/animes?search=${name}`,
            }),
        }),
    }),
});

export const { useGetAnimeScoreQuery } = shikimoriSlice;