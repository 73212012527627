import styles from './AnimeCard.module.css';
import {Link} from 'react-router-dom';
import {motion} from "framer-motion";
import {litelibria_poster_url} from "../../../api/constants";

const AnimeCard = ({title}) => {
	const {id, code, names, genres, status, player, type} = title;


	function formatEpisodesCount(episodes) {
		if (episodes % 10 === 1) {
			return `${episodes} серия`;
		} else if (
			episodes % 10 >= 2 &&
            episodes % 10 <= 4 &&
            (episodes % 100 < 10 || episodes % 100 >= 20)
		) {
			return `${episodes} серии`;
		} else {
			return `${episodes} серий`;
		}
	}


	const isCompleted = status.code === 2;
	const statusText = isCompleted ?
		`${type.episodes !== null ? formatEpisodesCount(type.episodes) : formatEpisodesCount(player.episodes.last)}`
		:
		`Вышло ${player.episodes.last} из ${type.episodes !== null ? type.episodes : '?'} серий`;

	const path = `/title/${code}`;

	return (
		<motion.div
			className={`${styles.title} ${"bg-white dark:bg-[#1D1D1D] shadow-custom"}`}
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.95 }}>
			<Link
				to={path}
				key={code}
			>
				<div>
					<div>
						<div className={styles['anime-img-wrapper']}>
							<div className={styles['poster-container']}>
								<img
									className={styles.poster}
									src={`${litelibria_poster_url}${id}.webp`}
									alt="poster"
									loading="lazy"
								/>
								<div className={styles['anime-episodes']}>{statusText}</div>
							</div>
						</div>
					</div>
					<h3 className={`${styles['title-name']} ${"text-black dark:text-white"}`}>{names.ru}</h3>
					<p className={styles.genres}>{genres.join(', ')}</p>
				</div>
			</Link>
		</motion.div>
	);
};

export default AnimeCard;