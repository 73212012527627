import React, { useEffect, useState } from "react";
import styles from "./PlayerMind.module.css";
import Select from "react-select";
import VidstackPlayer from "./VidstackPlayer/VidstackPlayer";
import AnilibriaPlayer from "./AnilibriaPlayer/AnilibriaPlayer";
import KodikPlayer from "./KodikPlayer/KodikPlayer";
import ZalupaPlayer from "./ZalupaPlayer/ZalupaPlayer";

function PlayerMind({id, player }) {
    const [playerID, setPlayerID] = useState("");
    const [playerText, setPlayerText] = useState("");

    const playersList = () => {
        const options = [];

        if (player.list && Object.keys(player.list).length > 0) {
            options.push({ value: "danime", label: "Danime Player" });
        }

        if (player.rutube && Object.keys(player.rutube).length > 0) {
            options.push({ value: "rutube", label: "Rutube Player" });
        }

        options.push({ value: "anilibria", label: "Anilibria Player" });

        if (player.alternative_player) {
            options.push({ value: "kodik", label: "Kodik Player" });
        }


        return options;
    };

    useEffect(() => {
        const defaultPlayer = playersList()[0];
        setPlayerID(defaultPlayer.value);
        setPlayerText(defaultPlayer.label);
    }, []);

    const handlePlayerChange = (selectedOption) => {
        const { value: player, label: playerText } = selectedOption;
        if (player !== playerID) {
            setPlayerID(player);
            setPlayerText(playerText);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#1D1D1D',
            color: '#FFFFFF',
            border: state.isFocused ? '2px solid #1D1D1D' : '2px solid #1D1D1D',
            boxShadow: state.isFocused ? 'none' : 'none',
            borderRadius: "10px",
            '&:hover': {
                border: state.isFocused ? '2px solid #1D1D1D' : '2px solid #1D1D1D'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#262626' : '#1D1D1D',
            color: '#FFFFFF',
            borderRadius: "10px",
            '&:hover': {
                backgroundColor: '#262626'
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#1D1D1D',
            marginTop: '5px',
            borderRadius: "10px",
        })
    };


    return (
        <div>
            {playerID !== "rutube" && (
                <div className={styles["selectors-right"]}>
                    <Select
                        className={styles.selectorRight}
                        value={{ value: playerID, label: playerText }}
                        options={playersList()}
                        onChange={handlePlayerChange}
                        styles={customStyles}
                        isSearchable={false}
                    />
                </div>
            )}
            {playerID === "danime" ? (
                <VidstackPlayer titleId={id} player={player}/>
            ) : playerID === "anilibria" ? (
                <AnilibriaPlayer id={id}/>
            ) : playerID === "kodik" ? (
                <KodikPlayer player={player}/>
            ) : playerID === "rutube" ? (
                <ZalupaPlayer player={player} playersList={playersList()} playerID={playerID} playerText={playerText} onChangePlayer={handlePlayerChange}  />
            ) : null}
        </div>

    );
}

export default PlayerMind;
