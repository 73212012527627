import SwiperCore from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './AnimeSlider.css';
import AnimeCard from "../AnimeCard/AnimeCard";

SwiperCore.use([Navigation, Pagination, Autoplay]);


const AnimeSlider = ({animeArr}) => {

    const swiperResponsive = {
        0: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        361:{
            slidesPerView: 2,
            spaceBetween: 10,
        },
        588:{
            slidesPerView: 3,
            spaceBetween: 5,
        },
        700:{
            slidesPerView: 4,
            spaceBetween: 5,
        },
        810: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        1179:{
            slidesPerView: 5,
            spaceBetween: 40,
        },
        1280: {
            slidesPerView: 5,
            spaceBetween: 40,
        },
        1920: {
            slidesPerView: 6,
            spaceBetween: 40,
        },
    };

    return (
        <Swiper
            navigation
            pagination={{ clickable: true }}
            breakpoints={swiperResponsive}
        >
            {animeArr.map((anime) => (
                <SwiperSlide key={anime.code}>
                    <AnimeCard anime={anime} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default AnimeSlider;
