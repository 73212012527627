import React from 'react';

function AnilibriaPlayer({ id }) {
    return (
        <iframe
            title="player"
            src={`https://www.anilibria.tv/public/iframe.php?id=${id}`}
            width="100%"
            height={window.innerWidth < 768 ? '220px' : '550px'}
            frameBorder="0"
            allow="fullscreen *"
        ></iframe>
    );
}

export default AnilibriaPlayer;
