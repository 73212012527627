import React from 'react';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import {useGetAnimeScoreQuery} from "../../api/shikimoriSlice";



const StyledRating = styled(Rating)(({ isMobile }) => ({

    '& .MuiRating-iconFilled': {
        color: '#ff005c',
        fontSize: isMobile ? '25px' : '35px',
    },
    '& .MuiRating-iconHover': {
        color: '#ff005c',
        fontSize: isMobile ? '25px' : '35px',
    },
    '& .MuiRating-iconEmpty': {
        color: '#ff005c',
        fontSize: isMobile ? '25px' : '35px',
    },
}));

function TitleRating({ names }) {

    const { data: enData, isLoading: enIsLoading } = useGetAnimeScoreQuery(names.en);
    const { data: ruData, isLoading: ruIsLoading } = useGetAnimeScoreQuery(names.ru);

    let rating = null;

    if (!enIsLoading) {
        rating = enData[0].score;

        if (rating === "0.0" && names.ru && !ruIsLoading) {
            rating = ruData[0].score;
        }
    }

    if (!rating) {
        return null;
    }else{
        return (
            <StyledRating
                name="stars"
                value={parseFloat(rating) / 2}
                precision={0.1}
                max={5}
                readOnly
                isMobile={window.innerWidth < 768}
            />
        );
    }
}

export default TitleRating;
