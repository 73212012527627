import {Link} from 'react-router-dom';
import Loading from "./Loading/Loading";
import AnimeCard from "./TitleList/AnimeCard/AnimeCard";
import {useGetAnimeScheduleQuery} from "../api/anilibriaSlice";
import Error from "./Error";
import {motion} from "framer-motion";

const HomePage = () => {

    const {data, isError, isLoading} = useGetAnimeScheduleQuery("id,code,names.ru,genres,type.episodes,status.code,player.episodes");

    const getPreviousDayTitles = () => {
        let titleCount = 12;

        const currentDate = new Date();
        let previousDay = (currentDate.getDay() + 5) % 7; // Получаем индекс предыдущего дня
        const previousDaySchedule = data.find(item => item.day === previousDay);
        let titles = [];

        if (previousDaySchedule) {
            titles = previousDaySchedule.list.slice(0, titleCount);
        }

        while (titles.length < titleCount) {
            const nextDay = (previousDay + 1) % 7; // Получаем индекс следующего дня
            const nextDaySchedule = data.find(item => item.day === nextDay);

            if (nextDaySchedule) {
                const remainingTitles = nextDaySchedule.list.slice(0, titleCount - titles.length);
                titles = titles.concat(remainingTitles);
            }

            previousDay = nextDay;
        }

        return titles;
    };



    if(isLoading){
        return (<Loading/>);
    }
    else if(isError){
        return (<Error/>);
    }
    else {
        const titles = getPreviousDayTitles();

        return (
            <div className="felx">
                <div className="flex flex-col items-center text-center my-48">
                    <motion.h1
                        className="mx-2 mb-1 text-4xl opacity-0 sm:text-6xl text-black dark:text-white max-w-[1225px] font-bold"
                        animate={{opacity: 1}}
                        transition={{delay: 1}}>
                        ДОБРО ПОЖАЛОВАТЬ В МИР АНИМЕ
                    </motion.h1>
                    <motion.p
                        className="text-lg opacity-0 text-[#4E4E4E] mb-5"
                        animate={{opacity: 1}}
                        transition={{delay: 1.5}}>
                        Лучшие сериалы и фильмы ждут вас!
                    </motion.p>
                    <Link to="/list">
                        <motion.div className="opacity-0"
                                    animate={{opacity: 1}}
                                    transition={{delay: 1.5}}>
                            <motion.button
                                className="px=[20px] py-[10px] w-36 sm:w-[350px] bg-[#FF005C] text-white rounded-[20px] text-xl cursor-pointer"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}>
                                Смотреть
                            </motion.button>
                        </motion.div>
                    </Link>
                </div>
                <div className="mt-12">
                    <motion.div
                        className="flex flex-col text-center items-center opacity-0"
                        animate={{opacity: 1}}
                        transition={{delay: 2}}>
                        <svg className="fill-black dark:fill-white" width="67" height="88" viewBox="0 0 67 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.0162 65.4712L57.2518 42.1343C57.623 41.7449 57.8301 41.2276 57.8301 40.6896C57.8301 40.1516 57.623 39.6343 57.2518 39.2449L57.2267 39.2198C57.0467 39.0304 56.8301 38.8796 56.59 38.7765C56.35 38.6734 56.0914 38.6203 55.8301 38.6203C55.5689 38.6203 55.3103 38.6734 55.0703 38.7765C54.8302 38.8796 54.6136 39.0304 54.4336 39.2198L33.4961 61.1958L12.567 39.2198C12.387 39.0304 12.1704 38.8796 11.9304 38.7765C11.6903 38.6734 11.4317 38.6203 11.1705 38.6203C10.9092 38.6203 10.6507 38.6734 10.4106 38.7765C10.1705 38.8796 9.95388 39.0304 9.77393 39.2198L9.7488 39.2449C9.37761 39.6343 9.17053 40.1516 9.17053 40.6896C9.17053 41.2276 9.37761 41.7449 9.7488 42.1343L31.9844 65.4712C32.18 65.6765 32.4152 65.8399 32.6757 65.9515C32.9363 66.0632 33.2168 66.1207 33.5003 66.1207C33.7838 66.1207 34.0643 66.0632 34.3249 65.9515C34.5855 65.8399 34.8206 65.6765 35.0162 65.4712Z"/>
                            <path d="M35.0162 44.4712L57.2518 21.1343C57.623 20.7449 57.8301 20.2276 57.8301 19.6896C57.8301 19.1516 57.623 18.6343 57.2518 18.2449L57.2267 18.2198C57.0467 18.0304 56.8301 17.8796 56.59 17.7765C56.35 17.6734 56.0914 17.6203 55.8301 17.6203C55.5689 17.6203 55.3103 17.6734 55.0703 17.7765C54.8302 17.8796 54.6136 18.0304 54.4336 18.2198L33.4961 40.1958L12.567 18.2198C12.387 18.0304 12.1704 17.8796 11.9304 17.7765C11.6903 17.6734 11.4317 17.6203 11.1705 17.6203C10.9092 17.6203 10.6507 17.6734 10.4106 17.7765C10.1705 17.8796 9.95388 18.0304 9.77393 18.2198L9.7488 18.2449C9.37761 18.6343 9.17053 19.1516 9.17053 19.6896C9.17053 20.2276 9.37761 20.7449 9.7488 21.1343L31.9844 44.4712C32.18 44.6765 32.4152 44.8399 32.6757 44.9515C32.9363 45.0632 33.2168 45.1207 33.5003 45.1207C33.7838 45.1207 34.0643 45.0632 34.3249 44.9515C34.5855 44.8399 34.8206 44.6765 35.0162 44.4712Z" fillOpacity="0.5"/>
                        </svg>
                        <h3 className="text-4xl text-black dark:text-white mb-0">МОЖЕТ ВАМ ПОНРАВИТСЯ</h3>
                        <p className="text-xl mx-2 text-gray-500 mb-5">В этом списке вы увидите популярные аниме!</p>
                    </motion.div>
                    <motion.div className="flex flex-wrap justify-center h-full opacity-0"
                         animate={{opacity: 1}}
                         transition={{delay: 2}}>
                        {titles.map((title) => (
                            <div key={title.code}>
                                <AnimeCard title={title} />
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>

        );
    }
};

export default HomePage;
